import React, { useState } from 'react';
import './styles/App.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import AboutMe from './components/AboutMe';
import ContactMe from './components/ContactMe';
import ILoveYou from './components/Iloveyou';  // Your hidden page component
import Footer from './components/Footer';
import logo from './assets/images/logo.png';

function Home() {
    return (
        <div className="content">
            <img src={logo} className="front-page-logo img-fluid" alt="Front Page Logo" />
        </div>
    );
}

function App() {
    const [expanded, setExpanded] = useState(false);

    const closeMenu = () => {
        setExpanded(false); // Closes the menu after navigation
    };

    return (
            <div className="App" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
                {/* Navbar */}
                <Navbar expanded={expanded} expand="lg" sticky="top" className="custom-navbar">
                    <Container>
                        <Navbar.Brand as={Link} to="/" onClick={closeMenu}>
                            <img src={logo} className="logo" alt="Logo" style={{ height: '50px' }} />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="navbar-nav" onClick={() => setExpanded(!expanded)} />
                        <Navbar.Collapse id="navbar-nav">
                            <Nav className="ms-auto nav-links">
                                <Nav.Link as={Link} to="/" onClick={closeMenu}>Home</Nav.Link>
                                <Nav.Link as={Link} to="/about" onClick={closeMenu}>About Me</Nav.Link>
                                <Nav.Link as={Link} to="/contact" onClick={closeMenu}>Contact Me</Nav.Link>
                                {/* DO NOT INCLUDE the /iloveyou link here */}
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

                {/* Routes */}
                <div style={{ flexGrow: 1 }}>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/about" element={<AboutMe />} />
                        <Route path="/contact" element={<ContactMe />} />
                        <Route path="/iloveyou" element={<ILoveYou />} /> {/* Hidden route */}
                    </Routes>
                </div>

                {/* Footer */}
                <Footer />
            </div>
    );
}

export default App;
