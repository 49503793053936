import React, { useEffect } from 'react';
import '../styles/hidden.css';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function ILoveYou() {
    useEffect(() => {
        document.body.classList.add('hidden-page-body');
        const confettiContainer = document.createElement('div');
        confettiContainer.style.position = 'absolute';
        confettiContainer.style.width = '100%';
        confettiContainer.style.height = '100%';
        confettiContainer.style.top = '0';
        confettiContainer.style.left = '0';
        confettiContainer.style.pointerEvents = 'none';
        document.body.appendChild(confettiContainer);

        for (let i = 0; i < 100; i++) {
            const confettiPiece = document.createElement('div');
            confettiPiece.classList.add('confetti-piece');
            confettiPiece.style.left = `${Math.random() * 100}%`;
            confettiPiece.style.backgroundColor = getRandomColor();
            confettiPiece.style.animationDelay = `${Math.random() * 3}s`;
            confettiPiece.style.animationDuration = `${Math.random() * 3 + 3}s`;
            confettiContainer.appendChild(confettiPiece);
        }

        function getRandomColor() {
            const colors = ['#FFC107', '#FF5722', '#03A9F4', '#4CAF50', '#E91E63'];
            return colors[Math.floor(Math.random() * colors.length)];
        }

        return () => {
            document.body.classList.remove('hidden-page-body');
            document.body.removeChild(confettiContainer);
        };
    }, []);

    return (
        <div className="hidden-page">
            <h1>To the Birthday Girl</h1>
            <p>Well, well, well… looks like I finally gave you your birthday gift. I really hope you loved it because,
                to be honest, I’ve never put this much effort into someone’s gift. Before I get too deep into this, I
                want to let you know that the page you're on right now is a hidden page on the website. If you ever want
                to come back to it, just type in <Link
                    to="https://kendallspangenberg.com/#iloveyou">https://kendallspangenberg.com/#iloveyou</Link> to the
                browser search bar. If you want me to take it down or get rid of it, just tell me.</p>
            <p>Now, let’s get into it.</p>
            <p>I remember the day we met like it was yesterday, we’ve obviously talked about it a lot. I was ordering a
                High Noon at the District, and when I turned around, I saw Easton sitting with you, KT, and Sam. I
                didn’t know any of your names at the time, but I remember seeing you and thinking, "Wow, she’s
                adorable." I sat down next to you and felt like I weirded you out a bit, especially when Easton started
                yelling, “This is my friend Tanner!” Then you and I looked at eachother, and I just remember thinking,
                wow this girl is beautiful!.
            </p>
            <p>KT asked for my Instagram, and I gave it to her, but the whole time, I really wanted your number. I was
                too dumb to ask, though, and it’s honestly one of my biggest regrets. I’m so thankful KT asked for my
                Instagram and that you begged for it. I still laugh about how, when I saw you follow me, I went to DM
                you, but you beat me to it, you’re always one step ahead of me! And, of course, you turned me down when
                I asked for your number (so mean). I thought you weren’t interested and just wanted to be friends, so I
                backed off for a bit. But that didn’t last long, especially after you asked me to hang out.
            </p>
            <p>When we finally hung out, we both know how that went. I honestly didn’t expect you to text me afterward
                because I felt like a total asshole, but I caught feelings right away and just tried to hide them. I was
                at the rink waiting for a text, and when it finally came, I was so surprised and excited. I couldn’t
                wait to hang out again, and then you totally shot me with an arrow.
            </p>
            <p>After the Angel game, I randomly called you and asked if you wanted to come out with us. I really didn’t
                think you’d say yes, but you surprised me again. You showed up, and I remember how nervous I was waiting
                for you. When I finally saw you, my heart dropped. I introduced you to my friends, and then we went
                outside, where we had a really personal conversation about our life goals. We had completely different
                ideas about the future. I told you how I wanted to get married, have kids, and own a house, and you told
                me that wasn’t really in your plans and that you sometimes did things I wasn’t a fan of. But even as you
                were saying all that, I was looking into your eyes, and I fell in love with you right then and there. At
                that moment, I knew I was going to marry you. From that point on, everything I wanted involved you.
            </p>
            <p>Now, after so many dates and spending almost every day together, you’re my girlfriend, and we’re one step
                closer to where we want to be.
            </p>
            <p>I know we have our moments, we fight, we go through hell sometimes—but every time we get through it, we
                come out stronger. When I’m with you, all my problems disappear. I get butterflies and I’m constantly
                nervous around you. Kendall, you are literally my heaven, and I’ll do anything to make you happy and
                give you the world. You know my deepest, darkest secrets, the things not even my closest friends know,
                and I wouldn’t change that for anything. I’d spend everything I have just to make sure you’re happy,
                because all I want is you.
            </p>
            <p>I could keep writing, but the more I go on, the messier this gets. I’ve never been great with words, so
                I’ll leave you with this. You are my world, my baby girl, the love of my life. I want you to have the
                best birthday ever, and I can’t wait for Vegas and all the fun things we’re going to do to celebrate.
                Let’s make this birthday extra special.
            </p>
            <h1>I love you!!</h1>
        </div>
    );
}

export default ILoveYou;
