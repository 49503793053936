import React from 'react';
import { Link } from 'react-router-dom';  // Import Link for internal navigation
import '../styles/App.css';

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-content">
                {/* Instagram link */}
                <a href="https://www.instagram.com/kendall.didit/" target="_blank" rel="noopener noreferrer">
                    <img src={require('../assets/images/instagram.png')} alt="Instagram" className="instagram-logo" />
                </a>
                <p>© 2024-2025 Kendall Spangenberg</p>
            </div>
        </footer>
    );
}

export default Footer;
