import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../styles/App.css';

function AboutMe() {
    return (
        <Container fluid className="about-me" style={{ padding: '5vh 0' }}>
            <Row className="justify-content-center">
                {/* Center the image */}
                <Col xs={12} md={6} className="text-center">
                    <img src={require('../assets/images/me.jpeg')} className="about-me-photo img-fluid" alt="About Me" />
                </Col>
            </Row>
            <Row className="justify-content-center">
                {/* Center the text */}
                <Col xs={12} md={8} className="text-center">
                    <h1 className="custom-font">About Me</h1>
                    <p>My name is Kendall Spangenberg, and I'm a licensed Cosmetologist
                        with a passion for helping people look and feel their best.
                        I specialize in hair styling, lash lifts, brow lamination,
                        and I'm Bellami-certified in extensions. I graduated from
                        Fullerton College's Cosmetology program in 2023, pursuing
                        a career in beauty because I've always been drawn to the
                        art of hair and making others feel confident.
                    </p>
                    <p>I have been working in the beauty industry for a while now and
                        truly love what I do. My goal is to provide a professional and
                        comfortable experience for all my clients ensuring they leave
                        feeling their absolute best.
                    </p>
                    <p>Currently, I’m cutting hair and working as an assistant at Rooted
                        Hair Co. in Yorba Linda. If you'd like to see examples of
                        my work, feel free to check out my Instagram by clicking the link
                        at the bottom of the page. For appointments, you can reach out to
                        me here or through my Instagram. I look forward to hearing from you!
                    </p>
                </Col>
            </Row>
        </Container>
    );
}

export default AboutMe;
